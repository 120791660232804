<template>
     <div class="w3-theme-l1" >
        <div class="w3-container w3-margin-top w3-margin-bottom">
            <Toolbar 
                :statusAdd="recAkses.add=='T'?true : false"
                :statusPrint="recAkses.print=='T'?true : false"
                :statusExport="recAkses.print=='T'?true : false"
                :click-add="showAdd"
                :click-refresh="loadData"
                :click-print="showPrint"
                :set-filter="setFilter"
                :field-find="filters.fields"
                help="Pm9euRNtPKw"
                :data   = "getRecs"
                :fields = "json_fields"
                type    = "xls"
                name    = "guru.xls"
            />
			<div class="w3-row">
				<div class="w3-col l7">
					<div @mouseover="grid = true" @mouseleave="grid = false" >
						<vue-scrolling-table v-bind:style="'height:'+  (screenHeight+40) +'px;'" :class="'w3-table w3-bordered w3-striped w3-white w3-small freezeFirstColumn freezeLastColumn'" :include-footer="true" :dead-area-color="'w3-theme'" :scroll-horizontal="true" id="grid">
							<template slot="thead">
								<tr class="w3-border-0 w3-border-right w3-border-bottom">
									<th style="min-width:50px;" class="w3-light-grey w3-border-0 w3-border-right">
										No
									</th>
									<th v-for="fl in $store.getters['modGuru/filterByShow']" :key="fl.key" :style="'min-width:' + fl.width+
									(fl['sort']['status']?';cursor:pointer':'')"
										class="w3-light-grey w3-border-0" >
										<div v-on:click="setFilterTable(fl)">{{fl.name}}
											<span v-if="fl['sort']['status']">
												<i class="fa"
													:class="fl['sort']['asc']=='asc'?'fa-sort-asc':(fl['sort']['asc']=='desc'?'fa-sort-desc':'fa-arrows-v')"></i>
											</span>
										</div>
									</th>
								</tr>
							</template>
							<template slot="tbody">
								<tr :id="'grid-' + index" @mouseover="dtl['pilih']=true" @mouseleave="dtl['pilih']=false" v-for="(dtl, index) in getRecs" style="cursor: pointer;" :class="(dtl.pilih==true?'bayangan w3-theme-l4':(index == $store.getters['modGuru/selectedRow'] ?'bayangan w3-theme-l4':'w3-opacity-min'))" :key="dtl.id" v-on:dblclick="showEdit(dtl, index);" class=" w3-hover-border-grey">
									<td style="min-width:50px;" class="w3-light-grey w3-border-0 w3-border-right">
										<i :class="index == $store.getters['modGuru/selectedRow'] ?'fa fa-caret-right':''"></i>{{index+((pages.halaman-1)*pages.batas)+1}}
									</td>
                                    <td v-on:click="$store.dispatch('modGuru/setSelectedRow',index)" :style="'min-width:' + findObjFind('width','guru_nip')"  class="w3-border-0 w3-border-left">
										<span v-html="setTextFind(dtl.guru_nip,'guru_nip')"></span>
									</td>
                                    <td v-on:click="$store.dispatch('modGuru/setSelectedRow',index)" :style="'min-width:' + findObjFind('width','guru_singkatan')"  class="w3-border-0 w3-border-left">
										<span v-html="setTextFind(dtl.guru_singkatan,'guru_singkatan')"></span>
									</td>
                                    <td v-on:click="$store.dispatch('modGuru/setSelectedRow',index)" :style="'min-width:' + findObjFind('width','guru_name')"  class="w3-border-0 w3-border-left">
										<span v-html="setTextFind(dtl.guru_name,'guru_name')"></span>
									</td>
                                    <td v-on:click="$store.dispatch('modGuru/setSelectedRow',index)" :style="'min-width:' + findObjFind('width','guru_alamat')"  class="w3-border-0 w3-border-left">
										<span v-html="setTextFind(dtl.guru_alamat,'guru_alamat')"></span>
									</td>
                                    <td v-on:click="$store.dispatch('modGuru/setSelectedRow',index)" :style="'min-width:' + findObjFind('width','guru_telp')"  class="w3-border-0 w3-border-left">
										<span v-html="setTextFind(dtl.guru_telp,'guru_telp')"></span>
									</td>
                                    <td v-on:click="$store.dispatch('modGuru/setSelectedRow',index)" :style="'min-width:' + findObjFind('width','guru_email')"  class="w3-border-0 w3-border-left">
										<span v-html="setTextFind(dtl.guru_email,'guru_email')"></span>
									</td>
									
									
									<td v-on:click="$store.dispatch('modGuru/setSelectedRow',index)" :style="'min-width:' + findObjFind('width','user_entry')"  class="w3-border-0 w3-border-left">
										<span v-html="setTextFind(dtl.user_entry,'user_entry')"></span>
									</td>
									<td v-on:click="$store.dispatch('modGuru/setSelectedRow',index)" :style="'min-width:' + findObjFind('width','date_entry')"  class="w3-border-0 w3-border-left">
										{{dtl.date_entry | moment("DD-MM-yyyy HH:mm:ss")}}
									</td>
									<td v-on:click="$store.dispatch('modGuru/setSelectedRow',index)" :style="'min-width:' + findObjFind('width','user_edit')"  class="w3-border-0 w3-border-left">
										<span v-html="setTextFind(dtl.user_edit,'user_edit')"></span>
									</td>
									<td v-on:click="$store.dispatch('modGuru/setSelectedRow',index)" :style="'min-width:' + findObjFind('width','date_edit')"  class="w3-border-0 w3-border-left">
										{{dtl.date_edit | moment("DD-MM-yyyy HH:mm:ss")}}
									</td>

									<td style="padding: 3px 0px 0px 10px;" :style="'min-width:' + findObjFind('width','guru_status')" class="w3-border-0 w3-border-left w3-border-right w3-center">
										<div v-on:click="$store.dispatch('modGuru/setSelectedRow',index)" style="position: relative;">
											<span v-html="setStatus(dtl.guru_status)"></span>
											<div v-show="dtl['pilih']==true" style="position: absolute; top: -5px; left: -5px; text-align:center;" class="w3-opacity-off">
												<button v-if="recAkses.edit=='T'" style="padding: 5px 7px 5px 7px; margin-right:5px;" class="w3-btn w3-circle w3-tiny w3-theme-d3" v-on:click="showEdit(dtl, index)"><i class="fa fa-pencil w3-large"></i></button>
												<button v-if="recAkses.delete=='T'" style="padding: 5px 7px 5px 7px; margin-right:5px;" class="w3-btn w3-circle w3-tiny w3-red" v-on:click="showDelete(dtl, index)"><i class="fa fa-trash-o w3-large"></i></button>
											</div>
										</div>
									</td>
								</tr>
							</template>
							<template slot="tfoot">
								<Paging class="w3-center"
									:click-handler="loadPage"
									:prev-text="'Prev'"
									:next-text="'Next'"
									:container-class="'w3-bar'"
									:page-count="pages.ttlhalaman"
									:page-range="10"
									:margin-pages="2"
									:value="pages.halaman">
								</Paging>
							</template>
						</vue-scrolling-table>
					</div>					
				</div>
				<div class="w3-col l5 " style="padding-left:5px;">
                    <div class="w3-row w3-theme-d3" style="padding-right:20px;">
                        <div class="w3-col l7 w3-theme-d3" style="padding: 5px">Menu</div>
                        <div class="w3-col l1 w3-theme-d3" style="padding: 5px">Sts</div>
                        <div class="w3-col l1 w3-theme-d3" style="padding: 5px">Add</div>
                        <div class="w3-col l1 w3-theme-d3" style="padding: 5px">Edit</div>
                        <div class="w3-col l1 w3-theme-d3" style="padding: 5px">Del</div>
                        <div class="w3-rest w3-theme-d3" style="padding: 5px">Prt</div>
                    </div>
                    <div class="w3-row w3-theme-d1" style="padding-right:17px;">
                        <div class="w3-col l7 w3-theme-d1" style="padding: 5px"><span style="color: #A58057;">-</span></div>
                        <div class="w3-col l1 w3-theme-d1" style="padding: 5px">
                            <i v-on:click="setPilihSemua(header.guru_menu_status=!header.guru_menu_status,'guru_menu_status')"
                                style="cursor: pointer;" class="fa" :class="header.guru_menu_status==true?'fa-check-square-o':'fa-square-o'"></i>
                        </div>
                        <div class="w3-col l1 w3-theme-d1" style="padding: 5px">
                            <i v-on:click="setPilihSemua(header.guru_menu_add=!header.guru_menu_add, 'guru_menu_add')"
                                style="cursor: pointer;" class="fa" :class="header.guru_menu_add==true?'fa-check-square-o':'fa-square-o'"></i>
                        </div>
                        <div class="w3-col l1 w3-theme-d1" style="padding: 5px">
                            <i v-on:click="setPilihSemua(header.guru_menu_edit=!header.guru_menu_edit, 'guru_menu_edit')"
                                style="cursor: pointer;" class="fa" :class="header.guru_menu_edit==true?'fa-check-square-o':'fa-square-o'"></i>
                        </div>
                        <div class="w3-col l1 w3-theme-d1" style="padding: 5px">
                            <i v-on:click="setPilihSemua(header.guru_menu_delete=!header.guru_menu_delete, 'guru_menu_delete')"
                                style="cursor: pointer;" class="fa" :class="header.guru_menu_delete==true?'fa-check-square-o':'fa-square-o'"></i>
                        </div>
                        <div class="w3-rest w3-theme-d1" style="padding: 5px">
                            <i v-on:click="setPilihSemua(header.guru_menu_print=!header.guru_menu_print, 'guru_menu_print')"
                                style="cursor: pointer;" class="fa" :class="header.guru_menu_print==true?'fa-check-square-o':'fa-square-o'"></i>
                        </div>
                    </div>
                    <div class="w3-row w3-white w3-responsive" v-bind:style="'height:'+ (screenHeight-25) +'px;'">
                        <div v-for="dtl in getRecDetails" :key="dtl.mn_id">
                            <div class="w3-col l7 w3-border-bottom" v-html="dtl.mn_name" style="padding-left: 5px"></div>
                            <div class="w3-col l1"><i title="Status" style="cursor:pointer;" v-on:click="chengeMenu(dtl,'guru_menu_status')" class="fa" :class="dtl.guru_menu_status=='T'?'fa-check-square-o':'fa-square-o'"></i></div>
                            <div class="w3-col l1"><i v-if="dtl.mn_type=='P'" title="Tambah" style="cursor:pointer;" v-on:click="chengeMenu(dtl,'guru_menu_add')" class="fa" :class="dtl.guru_menu_add=='T'?'fa-check-square-o':'fa-square-o'"></i></div>
                            <div class="w3-col l1"><i v-if="dtl.mn_type=='P'" title="Edit" style="cursor:pointer;" v-on:click="chengeMenu(dtl,'guru_menu_edit')" class="fa" :class="dtl.guru_menu_edit=='T'?'fa-check-square-o':'fa-square-o'"></i></div>
                            <div class="w3-col l1"><i v-if="dtl.mn_type=='P'" title="Hapus" style="cursor:pointer;" v-on:click="chengeMenu(dtl,'guru_menu_delete')" class="fa" :class="dtl.guru_menu_delete=='T'?'fa-check-square-o':'fa-square-o'"></i></div>
                            <div class="w3-col l1"><i v-if="dtl.mn_type=='P'" title="Print" style="cursor:pointer;" v-on:click="chengeMenu(dtl,'guru_menu_print')" class="fa" :class="dtl.guru_menu_print=='T'?'fa-check-square-o':'fa-square-o'"></i></div>
                        </div>
                    </div>
                    
				</div>
			</div>
        </div>
        <div id="modalGuru" class="w3-modal">
            <div class="w3-modal-content w3-animate-zoom w3-card-4 w3-theme-d3 w3-round-large" style="width:600px;position: absolute;top: 50px;" id="modalBodyGuru">
                <header class="w3-container w3-theme-d3 w3-round-large" style="cursor:move;" id="modalBodyGuruheader" >
                    <span onclick="document.getElementById('modalGuru').style.display='none'"
                    class="w3-button w3-display-topright w3-round-large">&times;</span>
                    <h6><i class="fa fa-windows"></i> Add / Edit data</h6>
                </header>
                <div class="w3-container w3-padding w3-theme-l4">
                    <form method="post" id="formGuru" v-on:submit.prevent="saveData" action="#" autocomplete="off" >
                        <div class="w3-row" style="margin-top:5px;">
                            <div class="w3-col l2 m3 s12 w3-right-align w3-hide-small" style="padding-top: 5px;padding-right: 5px">
                                <label for="guru_nip">{{findObjFind('name','guru_nip')}} :</label>
                            </div>
                            <div class="w3-col l4 m9 s12">
                                <input ref="guru_nip" required type="text" class="w3-input w3-small" id="guru_nip" :placeholder="findObjFind('name','guru_nip')" v-model="$store.getters['modGuru/rec'].guru_nip">
                            </div>
                            <div class="w3-col l2 m3 s12 w3-right-align w3-hide-small" style="padding-top: 5px;padding-right: 5px">
                                <label for="guru_singkatan">{{findObjFind('name','guru_singkatan')}} :</label>
                            </div>
                            <div class="w3-col l4 m9 s12">
                                <input ref="guru_singkatan" required type="text" class="w3-input w3-small" id="guru_singkatan" :placeholder="findObjFind('name','guru_singkatan')" v-model="$store.getters['modGuru/rec'].guru_singkatan">
                            </div>
                        </div>
                        <div class="w3-row" style="margin-top:5px;">
                            <div class="w3-col l2 m3 s12 w3-right-align w3-hide-small" style="padding-top: 5px;padding-right: 5px">
                                <label for="guru_name">{{findObjFind('name','guru_name')}} :</label>
                            </div>
                            <div class="w3-col l10 m9 s12">
                                <input ref="guru_name" required type="text" class="w3-input w3-small" id="guru_name" :placeholder="findObjFind('name','guru_name')" v-model="$store.getters['modGuru/rec'].guru_name">
                            </div>
                        </div>
                        <div class="w3-row" style="margin-top:5px;">
                            <div class="w3-col l2 m3 s12 w3-right-align w3-hide-small" style="padding-top: 5px;padding-right: 5px">
                                <label for="guru_alamat">{{findObjFind('name','guru_alamat')}} :</label>
                            </div>
                            <div class="w3-col l10 m9 s12">
                                <textarea class="w3-input w3-small" id="guru_alamat" :placeholder="findObjFind('name','guru_alamat')" v-model="$store.getters['modGuru/rec'].guru_alamat"></textarea>
                            </div>
                        </div>
                        <div class="w3-row" style="margin-top:5px;">
                            <div class="w3-col l2 m3 s12 w3-right-align w3-hide-small" style="padding-top: 5px;padding-right: 5px">
                                <label for="guru_telp">{{findObjFind('name','guru_telp')}} :</label>
                            </div>
                            <div class="w3-col l4 m9 s12">
                                <input required type="text" class="w3-input w3-small" id="guru_telp" :placeholder="findObjFind('name','guru_telp')" v-model="$store.getters['modGuru/rec'].guru_telp">
                            </div>
                            <div class="w3-col l2 m3 s12 w3-right-align w3-hide-small" style="padding-top: 5px;padding-right: 5px">
                                <label for="guru_email">{{findObjFind('name','guru_email')}} :</label>
                            </div>
                            <div class="w3-col l4 m9 s12">
                                <input required type="text" class="w3-input w3-small" id="guru_email" :placeholder="findObjFind('name','guru_email')" v-model="$store.getters['modGuru/rec'].guru_email">
                            </div>
                        </div>
                        <div class="w3-row" style="margin-top:5px;">
                            <div class="w3-col l2 m3 s12 w3-right-align w3-hide-small" style="padding-top: 5px;padding-right: 5px">
                                <label for="guru_level">{{findObjFind('name','guru_level')}} :</label>
                            </div>
                            <div class="w3-col l1 m9 s12">
                                <input ref="guru_level" required type="number" class="w3-input w3-small" id="guru_level" :placeholder="findObjFind('name','guru_level')" v-model="$store.getters['modGuru/rec'].guru_level">
                            </div>

                            <div class="w3-col l2 m3 s12 w3-right-align w3-hide-small" style="padding-top: 5px;padding-right: 5px">
                                <label for="guru_password">{{findObjFind('name','guru_password')}} :</label>
                            </div>
                            <div class="w3-col l3 m9 s12">
                                <input ref="guru_password" type="password" class="w3-input w3-small" id="guru_password" :placeholder="findObjFind('name','guru_password')" v-model="$store.getters['modGuru/rec'].guru_password">
                            </div>
                            <div class="w3-col l1 m3 s12 w3-right-align w3-hide-small" style="padding-top: 5px;padding-right: 5px">
                                <label for="guru_password2">Ulang</label>
                            </div>
                            <div class="w3-col l3 m9 s12">
                                <input ref="guru_password2" type="password" class="w3-input w3-small" id="guru_password2" placeholder="Ulang Password" v-model="$store.getters['modGuru/rec'].guru_password2">
                            </div>
                        </div>
                        <div class="w3-row" style="margin-top:5px;">
                            <div class="w3-col l2 m3 s12 w3-right-align w3-hide-small" style="padding-top: 5px;padding-right: 5px">
                                <label for="guru_status">&nbsp;</label>
                            </div>
                            <div class="w3-col l2 m9 s12">
                                <label class="container w3-small">{{findObjFind('name','guru_status')}}
                                    <input id="guru_status" v-model="$store.getters['modGuru/rec'].guru_status" type="checkbox" true-value="T" false-value="F">
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                        </div>

                    </form>
                </div>
                <footer class="w3-container w3-theme-l1">
                    <label class="container w3-small w3-left" style="margin-top: 15px;">Auto add
                        <input true-value="T" false-value="F" v-model="pages.autoadd" type="checkbox" checked="checked">
                        <span class="checkmark"></span>
                    </label>
                    <h6 class="w3-right">
                        <button form="formGuru" type="submit" class="w3-btn w3-small w3-theme-d5" style="margin-right:5px;"><i class="fa fa-save"></i> Save</button>
                        <button onclick="document.getElementById('modalGuru').style.display='none'" class="w3-btn w3-small w3-red"><i class="fa fa-close"></i> Close</button>
                    </h6>
                </footer>
            </div>
        </div>
		
	</div>
</template>
<script>
import moment from "moment";
import md5 from "@/assets/doevent/md5.js";
export default {
    head: {
        title: {
            inner: "Master Guru"
        },
        script: []
    },
    name: "Guru",
    components: {},
    data () {
        return {
            "header" : {"guru_menu_status":true, "guru_menu_add" : true, "guru_menu_edit" : true, "guru_menu_delete" : true, "guru_menu_print": true},
            "recAkses": {"detail":[],"add":"F","edit":"F","delete":"F","print":"F"},
            "grid": false,
            "gridDetail": false,
            "screenHeight": 0,
            "pages": {"halaman": 1, "batas": 1, "ttlhalaman": 0, "ttlrec": 0, "autoadd": "T"},
            "filters": {"fields":[]},
            "filterDetails": {"fields":[]},
            "json_fields": { },
            "money": {"decimal": ",", "thousands": ".", "prefix": "", "suffix": "", "precision": 0, "masked": false}
        };
    },
    methods: {
        findObjFind (key, val) {
            return this.findObj(this.filters.fields, "key", val)[key];
        },
        findObjDetail (key, val) {
            // console.log(key, val);
            return this.findObj(this.filterDetails.fields, "key", val)[key];
        },
        setTextFind (txt, field) {
            const self = this;
            let fieldvalue = "";
            let ketemu = false;
            if (self.filters.find !== undefined) {
                if (self.filters.find.length > 0) {
                    self.filters.find.map(function (value) {
                        if (value.field === field) {
                            ketemu = true;
                        }
                        if (fieldvalue !== "null") {
                            fieldvalue += (fieldvalue !== "" ? ";" : "") + value.fieldValue;
                        } else {
                            fieldvalue += (fieldvalue !== "null" ? ";" : "") + value.fieldValue;
                        }
                    });
                }
            }
            if (ketemu) {
                return self.sethighlight(txt, fieldvalue);
            }
            return txt;
        },
        loadPage (pageNum) {
            this.pages.halaman = pageNum;
            this.$store.dispatch("modGuru/setPages",this.pages);
            this.$store.dispatch("modGuru/setFilters",this.filters);
            this.loadData();
        },
        setFilter (filter, operator) {
            this.filters.find = filter;
            this.filters.operator = operator;
            this.$store.dispatch("modGuru/setFilters",this.filters);
            this.loadData();
        },
        loadData () {
            this.$store.dispatch("modGuru/setSelectedRow",-1);
            // this.$store.dispatch("modGuruMenu/setSelectedRow",-1);
            this.$store.dispatch("modGuru/loadData");
        },
        async showAdd () {
            let key = await this.getCode();
            this.$store.dispatch("modGuru/setRec",{
                "event" : "add",
                "pilih": false,
                "guru_code": key,
                "guru_singkatan": "",
                "guru_name": "",
                "guru_alamat": "",
                "guru_telp": "",
                "sekolah_code": "c4ca4238a0b923820dcc509a6f75849b",
                "guru_password": "",
                "guru_salt": "",
                "guru_email": "",
                "guru_key": "",
                "guru_foto": "",
                "guru_nip": "",
                "guru_level": "1",
                "guru_status": "T",
                "user_edit": localStorage.username,
                "user_entry": localStorage.username,
                "date_entry": moment(String(new Date())).format("YYYY-MM-DD HH:mm:ss"),
                "date_edit": moment(String(new Date())).format("YYYY-MM-DD HH:mm:ss")
            });
            document.getElementById("modalGuru").style.display = "block";
            document.getElementById("guru_nip").focus();
        },
        showEdit (dt, idx) {
            dt["guru_password"] = "";
            dt["guru_password2"] = "";
            dt["guru_salt"] = "";
            this.$store.dispatch("modGuru/setSelectedRow",idx);
            this.$store.dispatch("modGuru/setRec",dt);
            document.getElementById("modalGuru").style.display = "block";
        },
        showDelete (dt) {
            const self = this;
            // eslint-disable-next-line no-undef
            swal(
                {
                    title: "Yakin Ingin Menghapus Data ..!",
                    text: "Data Yang sudah di hapus tidak akan bisa dikembalikan",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "Ya, Hapus Data",
                    cancelButtonText: "Tidak, Batal Hapus",
                    closeOnConfirm: true,
                    closeOnCancel: true
                },
                function (isConfirm) {
                    if (isConfirm) {
                        let vparams = { id: dt.id };
                        self.apiPost("/brantas/guru/delete", vparams, function (response) {
                            if (response.success === true) {
                                self.$toast.success("Hapus sukses", "Delete");
                                self.$store.dispatch("modGuru/deleteRecs", self.$store.getters["modGuru/selectedRow"] );
                            } else {
                                self.$toast.error("Gagal Hapus " + response.msg, "Delete");
                            }
                        });
                    }
                }
            );
        },
        showPrint () {},
        setStatus (dt) {
            if (dt === "T") {
                return "<div class='w3-green w3-center w3-round' style='width:50px;margin-top:5px;'>Active</div>";
            }
            return "<div class='w3-red w3-center w3-round' style='width:50px;margin-top:5px;'>In Active<div>";
        },
        saveData (event) {
            const self = this;
            if (self.$store.getters["modGuru/rec"].guru_password != self.$store.getters["modGuru/rec"].guru_password2){
                self.$toast.error("Password tidak sama", "Save");
                return false;
            }
            self.$store.getters["modGuru/rec"]["guru_password"] = md5(self.$store.getters["modGuru/rec"].guru_password2);

            self.apiPost("/brantas/guru/" + self.$store.getters["modGuru/rec"].event, self.$store.getters["modGuru/rec"], function (response) {
                
                if (response.success) {
                    if (self.$store.getters["modGuru/rec"].event == "add") {
                        self.$store.getters["modGuru/rec"].id = response.key;
                        self.$store.dispatch("modGuru/addRecs", self.$store.getters["modGuru/rec"]);
						
                        // self.rec.id = response.key;
                        // self.$store.dispatch("modGuru/addRecs", self.rec);
                    } else {
                        self.$store.dispatch("modGuru/editRecs", {"rec":self.$store.getters["modGuru/rec"], "index": self.$store.getters["modGuru/selectedRow"]});
                    }
                    document.getElementById("modalGuru").style.display = "none";
                    self.$toast.success("Sukses Simpan data", "save");
                    if (self.pages.autoadd === "T") {
                        self.showAdd();
                    }
                } else {
                    // swal({title: "Save",text: "Gagal simpan data",type: "error",html: true});
                    self.$toast.error("Simpan gagal " + response.msg, "Save");
                }
            });
            event.preventDefault();
            return false;
        },
        setFilterTable(dt){
            if(!this.setFilterHeader(dt, this.filters)) {
                this.loadData();
            }
        },
        chengeMenu(dtl,sts){
            const self = this;
            if(dtl[sts]=="T"){
                dtl[sts]="F";
            }else{
                dtl[sts]="T";
            }
            let obj = dtl;
            obj[`${sts}`] = dtl[sts];
            // obj["guru_menu_code"] = dtl.id;
            // eslint-disable-next-line no-console
            // console.log("isi menu",obj);
            self.apiPost("/brantas/gurumenu/edit" , obj,function(response){
                if(response.success==true){
                    self.$toast.success("Sukses Simpan data");
                }else{
                    self.$toast.error("Gagal simpan data");
                    if(dtl[sts]=="T"){
                        dtl[sts]="F";
                    }else{
                        dtl[sts]="T";
                    }
                }
            });
        },
        setPilihSemua(sts, pilihan){
            let self = this;
            self.$store.getters["modGuruMenu/recMenus"].map(function(value){
                value[pilihan] = (sts==true)?"F":"T";
                self.chengeMenu(value,pilihan);
            });
        }
    },
    computed: {
        getRecs() {
            return this.$store.getters["modGuru/recs"];
        },
        getRecDetails() {
            return this.$store.getters["modGuruMenu/recMenus"];
        }
    },
    filters: {
        toRp(value) {
            // eslint-disable-next-line new-cap
            return `${Intl.NumberFormat("de-DE").format(value)}`;
        }
    },
    created(){
        this.$store.getters["modGuru/filters"].find = [];
        this.pages = this.$store.getters["modGuru/pages"];
        this.filters = this.$store.state.modGuru.filters;
        // this.filterDetails = this.$store.state.modGuruMenu.filters;
        // if (this.$store.getters["modMenu/recs"].length==0){
        //     this.$store.dispatch("modMenu/loadData");
        // }
    },
    mounted () {
        this.recAkses = Object.assign({}, this.$store.state.modAksesmenu.recAkses[this.$route.name.toUpperCase()]);
        this.screenHeight = window.innerHeight -190;
        this.getGridColumnmAutoWidth("grid",this.filters.fields, false);
        this.loadData();
        const self = this;
        this.eventGridStore(this, "grid", "modGuru/selectedRow",function(nilai){
            self.$store.dispatch("modGuru/setSelectedRow",nilai);
        });
        self.$store.watch(
            state => state.modGuru.selectedRow, (baru, lama)=> {
                let vparams = {};
                if (baru!=lama){
                    self.$store.dispatch("modGuruMenu/setRecMenus",[]);
                    if (baru == -1){
                        self.$store.dispatch("modGuruMenu/setRecs", []);
                    }else if(baru != -1) {
                        const dtl = self.$store.getters["modGuru/recs"][baru];
                        // console.log("PO", dtl);
                        self.$store.dispatch("modGuru/setRec",dtl);
                        self.rec = Object.assign({}, dtl);
                        vparams = {
                            operator: "AND",
                            kondisi: "=",
                            field: "guru.guru_code",
                            fieldvalue: dtl.guru_code,
                            limit: 1000,
                            offset: 0,
                            order: "mn_urut"
                        };
                        self.$store.dispatch("modGuruMenu/loadData", vparams);
                    }
                    // eslint-disable-next-line no-console
                    // console.log(vparams);
                    
                }
            }
        );
        this.dragElement(document.getElementById("modalBodyGuru"));
    }
};
</script>
